// Ezzing Layout V3
var ez3dLayout = new Ezzing3D(); // eslint-disable-line no-undef
var container = document.getElementById('container');

var hash;
var mode;
var url = window.location.href;
const htmlfile = "ez3d-main-container.html";

// check viewport mode in url
var hashPositionInit = url.indexOf('#');
if (hashPositionInit > 0) {
    var hashAndMode = url.slice(hashPositionInit + 2, url.length);
    hashAndMode = hashAndMode.split('/');
    hash = hashAndMode[0];
    mode = hashAndMode[1];
}

var modes = [undefined, 'showcase'];

var rules = {
    apiKey: undefined,
    // id: 41775,
    urlHash: hash,
    scenePreferences: {
        // defaultProjectId: 38951,
        viewportMode: modes.indexOf(mode) < 1
            ? undefined : modes.indexOf(mode),
        // viewportMode: 1  // set this to test showcase when starting
        // viewportMode: 3  // set this to test display none
    }
};

// viewportModes:
// mode 0 - invisible interface
// mode 1 - showcase mode
// mode 2 - scene and map
// mode 3 - hide interface - DEPRECATED
// mode 4 - scene and streetview
// mode 5 - scene and json editor
// mode 6 - simple mode
// mode 7 - scene and documentation
// mode 8 - autocad

// var rules = {
//     publicHash: hash,
//     scenePreferences: {
//         activeMapper: true,
//         activeRenderer: true,
//         angleBias: 0.5,
//         buildingTextures: true,
//         closeRightCanvas: true,
//         collisionBias: 0.03,
//         customLogoUrl: 'default',
//         debugPromises: false,
//         defaultLanguage: 'en',
//         defaultProjectId: 0,
//         defaultUnits: 'm',
//         enableApi: true,
//         enableNotifications: true,
//         enablePlayer: true,
//         enableUndoRedo: true,
//         gridOffsetX: 0,
//         gridOffsetY: 0,
//         gridSize: 1,
//         hidden: []
//         loadMockup: false,
//         manageFullscreen: true,
//         maxAllowedBuildings: 0,
//         maxAreaInclination: 75,
//         movementStep: 0.1,
//         rotationRatio: 1,
//         showCoordinates: false,
//         showLogo: true,
//         showNav: true,
//         showTotalPower: true,
//         snapShotCrm: true,
//         snapToGrid: false,
//         snapToGuides: true,
//         snapToLines: true,
//         snapToVertices: false,
//         sunSpeed: 1,
//         translationRatio: 1,
//         viewportMode: 4,
//         zoomRatio: 1,
//     },
//     defaultColors: {
//         cssColors: {
//             first: '#C95138',
//             second: '#3B416C',
//             third: '#A35D3E',
//             lightgray: '#CFC4A1',
//             mediumgray: '#B4653E',
//             darkgray: '#986767',
//             disabledbuttons: '#6B1E1E',
//             disabledCustomSwtch: '#FAC9BC',
//             primaryFont: '#ff0000',
//             white: '#ffffff',
//         },
//         customModulesColor: [
//             '#FAC9BC',
//             '#6B1E1E',
//             '#986767',
//             '#B4653E',
//             '#A35D3E',
//             '#3B416C',
//             '#C95138',
//             '#FFD943']
//     }
// };

var callback = function(err, layoutApi, ez3dMainContainer) {
    // Callback to get the layout object and the container;
    // example:
    if (layoutApi) {
        window.layoutApi = layoutApi;
        console.log('layoutApi ready!');
    }
    if (err) {
        console.error(err, ez3dMainContainer);
    }
};


rules.scenePreferences.bingApiKey = '@@bingApiKey';
rules.scenePreferences.googleApiKey = '@@googleApiKey';


ez3dLayout.bootstrap(container, htmlfile, rules, callback);
